<template>
	<v-container fluid>
		<headerVue :title="$t('menu.editAntivirus')" />
		<v-row v-if="validateForm">
			<v-col cols="12" style="text-align:center;">
				<v-alert type="error">Todos los campos son obligatorio</v-alert>
			</v-col>
		</v-row>
		<v-row v-if="validateNoSerie">
			<v-col cols="12" style="text-align:center;">
				<v-alert type="error">EL equipo con el Número de serie ingresado no existe</v-alert>
			</v-col>
		</v-row>
		<v-row>
			<v-col v-if="loading" cols="12" style="text-align:center;">
				<v-progress-circular
				:size="70"
				:width="7"
				color="purple"
				indeterminate></v-progress-circular>
			</v-col>
			<v-col cols="12" v-if="!loading">
				<v-card>
					<v-row>
						<v-col cols="1"></v-col><v-col cols="2">{{ $t("serieNumber") }}</v-col>
						<v-col cols="2">
							<v-text-field
							filled
							flat
							v-model="serieNumber"
							clearable
							@change="find()"
							append-icon="mdi-eye" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="1"></v-col>
						<v-col cols="2">
							<v-text-field filled readonly label="Marca" v-model="brand" />
						</v-col>
						<v-col cols="2">
							<v-text-field filled readonly label="modelo" v-model="modelo" />
						</v-col>
						<v-col cols="2">
							<v-text-field filled readonly label="Tipo de Equipo" v-model="equipmentType" />
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="1"></v-col>
						<v-col cols="2">
							<v-text-field filled flat label="Estatus" v-model="status" />
						</v-col>
						<v-col cols="2">
							<v-text-field filled flat label="Fecha de validación" v-model="validationdate" type="date" />
						</v-col>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" align-self="center">
				<v-btn @click="back()">{{ $t("back") }}</v-btn><v-btn color="primary" @click="update()"> {{ $t("save") }} </v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import headerVue from "../../components/header.vue";
import moment from 'moment-timezone';
export default {
	components: {
		headerVue,
	},
	data() {
		return {
			serieNumber: "",
			brand: "",
			status: "",
			validationdate: "",
			equipmentType: "",
			modelo: "",
			loading: true,
			validateForm:false,
			validateNoSerie: false,
			id:0,
		}
	},
	methods: {
		back() {
			this.$router.push("/Antivirus");
		},
		clean() {
			this.modelo = "";
			this.brand = "";
			this.equipmentType = "";
		},
		find() {
			this.loading = true;
			this.validateNoSerie = false;
			axios.get('https://consubanco.azurewebsites.net/api/CargaEquipoComputo/serie/'+this.serieNumber)
			.then(resp => {
				
				this.modelo = resp.data.modelo;
				this.equipmentType = resp.data.tipo;
				if( resp.data.marcas ){

					this.brand = resp.data.marcas.nombreDeMarca;
				}
				this.loading = false;
			})
			.catch(err => {
				
				this.validateNoSerie = true;
				this.loading = false;
				console.error(err);
			});
		},
		update(){
			
			this.validateForm = false;
			
			if( this.serieNumber && this.modelo && this.equipmentType &&
				this.status && this.validationdate
			){

				const headerCurrent = {
					'Content-type': 'application/json;',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': 'true'
				}
				
				var validationdateFormat = moment( this.validationdate ).utc().toJSON();

				var raw = JSON.stringify({
					"id": this.id,
					"numeroDeSerie": this.serieNumber,
					"estatus": this.status,
					"fechaValidacion": validationdateFormat
				});
				
				axios.put('https://consubanco.azurewebsites.net/api/Antivirus/'+this.id, raw, {
					headers: headerCurrent
				})
				.then((response) => {
					
					this.$router.push("/Antivirus");
				})
				.catch((error) => {
					console.warn("update => ", error);
				});
			}
			else{

				this.validateForm = true;
			}
		},
	},
	created(){

		this.id = this.$route.params.id;
		
		axios.get( 'https://consubanco.azurewebsites.net/api/Antivirus/'+this.id )
		.then(resp => {

			this.serieNumber = resp.data.numeroDeSerie;
			this.status = resp.data.estatus;
			this.validationdate = moment( resp.data.fechaValidacion ).format( "YYYY-MM-DD" );
			this.find();
			this.loading = false;
		})
		.catch(err => {
			
			console.error(err);
		});
	},
}
</script>
